<template>
  <div style="position: relative; margin-top: 15px;">

    <el-row :gutter="10">
      <el-col :span="o.span" v-for="(o, index) in tabs" :key="index" style="margin-bottom: 10px">
        <component  v-if="o.component" :is="o.component" :options="o.componentOptions" :config="o.config" @config-changed="configChanged($event, o)" style="background-color: white;" />
      </el-col>
    </el-row>
    
    <div v-if="accountInfo.user.edit_page" style="position: absolute; left: 300px; top: -45px;">
      <el-button type="text" @click="showForm_config">↓配置组件↓</el-button>
    </div>
    
    <config-form ref="configForm" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex';

import * as components from '@/config_components';
import ConfigForm from './Form_config.vue';

export default {
  components: {
    ConfigForm,
  },
  data() {
    return {
      tabIndex: '0',
      tabCount: [],
      config: null,
    }
  },
  computed: {
    ...mapState(['accountInfo']),
    tabs() {
      return this.config?.tabs;
    },
    configKey() {
      return `pageConfig_${this.$route.path}`;
    },
  },
  watch: {
    '$route.path'() {
      this.init();
    }
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.tabIndex = '0';
      this.tabCount = [];
      this.config = null;
      
      this.$api.executeArray(
        ['sys_params.list', { code: this.configKey }],
      ).then(([
        { list: [sys_params]},
      ]) => {
        this.config = JSON.parse((sys_params ? sys_params.value : null) || '{}');

        const totalCount = _.map(this.config.tabs, o => {
          const comp = _.get(components, o.component);
          return comp?.methods?.queryTotalCount?.(_.pick(this, ['accountInfo']))
        });

        if(_.compact(totalCount).length) {
          this.$api.executeArray(...totalCount).then(res => this.tabCount = res)
        }
      })
    },
    showForm_config() {
      this.$refs.configForm.show(this.config, {
        title: '配置',
        success: config => {
          this.config = config;
          this.$api.execute('sys_params.replace', { code: this.configKey, value: JSON.stringify(this.config) })
        }
      })
    },

    tabConfigChanged(config) {
      this.config = _.assign({
        tabs: _.map(this.config.tabs, (o, index) => index == this.tabIndex ? _.assign({}, o, { config }) : o)
      })
      this.$api.execute('sys_params.replace', { code: this.configKey, value: JSON.stringify(this.config) })
    },

    refreshCount() {
      const totalCount = _.map(this.config.tabs, o => {
        const comp = _.get(components, o.component);
        return comp?.methods?.queryTotalCount?.(_.pick(this, ['accountInfo']))
      });

      if(_.compact(totalCount).length) {
        this.$api.executeArray(...totalCount).then(res => this.tabCount = res)
      }
    }
  }
}
</script>

<style>

</style>
